import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { SkeletonModule } from 'primeng/skeleton';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';
import { MatTableModule } from '@angular/material/table';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    PageNotFoundComponent,
    PageHeaderComponent,
    SpinnerComponent
  ],
  exports: [
    PageHeaderComponent,
    SpinnerComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    SkeletonModule,
    ButtonModule,
    CheckboxModule,
    IconFieldModule,
    InputIconModule,
    InputTextModule,
    MatTableModule
  ]
})
export class LayoutModule { }

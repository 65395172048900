import { HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageService } from '@core/services/local-storage/local-storage.service';
import { NGXLogger } from 'ngx-logger';

abstract class HttpCache {
  abstract get(req: HttpRequest<any>): HttpResponse<any> | null;

  abstract put(req: HttpRequest<any>, res: HttpResponse<any>): void;

  abstract delete(req: HttpRequest<any>): boolean;
}

@Injectable({
  providedIn: 'root'
})
export class HttpCacheService implements HttpCache {
  static CACHEABLE_HEADER_KEY: string = 'Cacheable';

  cache: { [key: string]: HttpResponse<any> } = {};

  constructor(private log: NGXLogger, private localStorageService: LocalStorageService) {
  }

  /**
   * Get an item from the cache
   * @param req
   */
  get(req: HttpRequest<any>): HttpResponse<any> {
    return this.cache[req.urlWithParams];
  }

  /**
   * Put an item in the cache
   * @param req
   * @param res
   */
  put(req: HttpRequest<any>, res: HttpResponse<any>): void {
    const shouldCache = this.shouldCache(req);
    if (shouldCache) {
      this.cacheToLocal(req.urlWithParams, res);
    }
  }

  /**
   * Delete an item from the cache
   * @param req
   */
  delete(req: HttpRequest<any>): boolean {
    const cachedRequest = this.get(req);
    let returnVal = false;
    if (cachedRequest) {
      delete this.cache[req.urlWithParams];
      returnVal = true;
    }
    return returnVal;
  }

  /**
   * Determine if a request SHOULD be cached or not. The request must contain Cacheable header set tot TRUE
   *
   * @param req
   */
  shouldCache(req: HttpRequest<any>) {
    return req.headers.get(HttpCacheService.CACHEABLE_HEADER_KEY) === 'TRUE';
  }

  /**
   * Place the response in the local `cache` variable
   *
   * @param urlWithParams
   * @param res
   */
  cacheToLocal(urlWithParams: string, res: HttpResponse<any>) {
    this.cache[urlWithParams] = res;
  }
}

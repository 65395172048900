import packageInfo from 'package.json';

export const environment = {
  prod: false,
  appUrl: 'https://go.stage.qreview.app',
  idpBaseUrl: 'https://sso.stage.qreview.app',
  apiBaseUrl: 'https://api.stage.qreview.app'
};

export const PROJECT_NAME = packageInfo.name;
export const PROJECT_VERSION = packageInfo.version;

{
  "name": "qreview-go",
  "version": "0.0.0",
  "scripts": {
    "ng": "ng",
    "start:dev": "ng serve --host=0.0.0.0 --disable-host-check --configuration dev",
    "start:stage": "ng serve --host=0.0.0.0 --disable-host-check --configuration stage",
    "clean": "rm -rf dist",
    "build": "yarn build:dev",
    "build:dev": "ng build --configuration dev --output-hashing=all",
    "build:stage": "ng build --configuration stage --output-hashing=all",
    "build:prod": "ng build --configuration prod --output-hashing=all",
    "watch": "ng build --watch --configuration dev --output-hashing=all",
    "test": "ng test",
    "serve:ssr:qreview-go": "yarn build:stage && node dist/qreview-go/server/server.mjs",
    "import-ssl": "sudo bash ssl/import_certificate.sh"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.0.0",
    "@angular/cdk": "^17.0.4",
    "@angular/common": "^17.0.0",
    "@angular/compiler": "^17.0.0",
    "@angular/core": "^17.0.0",
    "@angular/forms": "^17.0.0",
    "@angular/material": "^17.0.4",
    "@angular/platform-browser": "^17.0.0",
    "@angular/platform-browser-dynamic": "^17.0.0",
    "@angular/platform-server": "^17.0.0",
    "@angular/router": "^17.0.0",
    "@angular/ssr": "^17.0.8",
    "angular-oauth2-oidc": "^17.0.1",
    "express": "^4.21.0",
    "ngx-logger": "^5.0.12",
    "primeng": "17.18.10",
    "rxjs": "~7.8.0",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.2"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.0.8",
    "@angular/cli": "^17.0.8",
    "@angular/compiler-cli": "^17.0.0",
    "@types/express": "^4.17.17",
    "@types/jasmine": "~5.1.0",
    "@types/node": "^18.18.0",
    "autoprefixer": "^10.4.16",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "postcss": "^8.4.32",
    "tailwindcss": "^3.4.0",
    "typescript": "~5.2.2"
  }
}

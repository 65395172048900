<div class="w-full h-[65px] px-10 py-4 justify-between items-center inline-flex border-b border-solid border-gray-200 bg-white">
  <div class="flex-row justify-center items-start gap-2.5 inline-flex">
    @if (titleService.page) {
      <div class="self-end text-zinc-900 text-2xl font-bold">{{ titleService.page }}</div>
      <div *ngIf="titleService.resource" class="self-end text-zinc-900 text-lg font-medium">{{ titleService.resource }}</div>
      <app-spinner *ngIf="titleService.loading" class="self-center" sizeClass="h-6 w-6"></app-spinner>
    } @else {
      <p-skeleton width="10rem" height="2rem" />
    }
  </div>
</div>

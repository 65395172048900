import { HttpClient, HttpContext, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpCacheService } from '@core/services/http/http-cache.service';

export class BaseHttpService {

  constructor(
    protected servicePath: string,
    protected httpClient: HttpClient
  ) { }

  protected get<T>(url: string, cacheable = false, options?: {
    headers?: HttpHeaders;
    context?: HttpContext;
    observe?: 'body';
    params?: HttpParams;
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<T> {
    if (!options) {
      options = {};
    }
    if (cacheable) {
      if (!options.headers) {
        options.headers = new HttpHeaders();
      }
      options.headers = options.headers.set(HttpCacheService.CACHEABLE_HEADER_KEY, 'TRUE');
    }
    return this.httpClient.get<T>(`/${this.servicePath}${url}`, options);
  }

  post<T>(url: string, body: any | null, options?: {
    headers?: HttpHeaders;
    context?: HttpContext;
    observe?: 'body';
    params?: HttpParams;
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<T> {
    return this.httpClient.post<T>(`/${this.servicePath}${url}`, body, options);
  }

  put<T>(url: string, body: any | null, options?: {
    headers?: HttpHeaders;
    context?: HttpContext;
    observe?: 'body';
    params?: HttpParams;
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<T> {
    return this.httpClient.put<T>(`/${this.servicePath}${url}`, body, options);
  }

  patch<T>(url: string, body: any | null, options?: {
    headers?: HttpHeaders;
    context?: HttpContext;
    observe?: 'body';
    params?: HttpParams;
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<T> {
    return this.httpClient.patch<T>(`/${this.servicePath}${url}`, body, options);
  }

  delete<T>(url: string, options?: {
    headers?: HttpHeaders;
    context?: HttpContext;
    observe?: 'body';
    params?: HttpParams;
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
    body?: any | null;
  }): Observable<T> {
    return this.httpClient.delete<T>(`/${this.servicePath}${url}`, options);
  }
}

import { Component, ContentChild, Input } from '@angular/core';
import { TitleService } from '@core/services/title.service';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrl: './page-header.component.scss'
})
export class PageHeaderComponent {
  @Input() title?: string;

  constructor(public titleService: TitleService) {
  }
}
